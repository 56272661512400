import queryString from "query-string"
import React, { FC, useEffect, useState } from "react"
import { useAnonymousToken } from "../../hooks"
import { getNextLifecycleState } from "./getLifecycleState"
import { LoadingScreen } from "./loadingScreen"
import { ClickToStartUnscheduled, WaitForGuideScheduled, WaitForGuideUnscheduled } from "./SessionLoaderDialogs"
import { SessionPage } from "./SessionPage"
import { useSession } from "./useSession"

interface SessionLoaderProps {
    location: Location
}

export const SessionLoader: FC<SessionLoaderProps> = ({ location }: SessionLoaderProps) => {
    const useAnonymousTokenData = useAnonymousToken()
    const { anonymousToken } = useAnonymousTokenData
    const queryParams = queryString.parse(location.search)
    const sessionBroadcastId = queryParams.session
    const [startingPrelude, setStartingPrelude] = useState(false)
    const [isSessionPollingEnabled, setIsSessionPollingEnabled] = useState(true)

    // we need to poll here
    const { session, inputsCompleted, updateInputsCompleted, startSessionEarly } = useSession({
        sessionBroadcastId,
        anonymousToken,
        isPollingEnabled: isSessionPollingEnabled,
    })

    const handleSessionStartClick = () => {
        if (session === "Loading") return
        if (typeof sessionBroadcastId !== "string") return
        console.log("starting prelude early")
        startSessionEarly(session.id, anonymousToken).then(() => setStartingPrelude(false))
    }

    const sessionLifeCycleState = getNextLifecycleState({ session })

    const isReadyToRenderSessionPage = sessionLifeCycleState === "default" && anonymousToken
    useEffect(() => {
        if (!isReadyToRenderSessionPage) return
        setIsSessionPollingEnabled(false)
    }, [isReadyToRenderSessionPage])

    if (sessionLifeCycleState === "initialising" || session === "Loading") {
        return <LoadingScreen />
    }
    if (sessionLifeCycleState === "wait-for-guide:unscheduled") {
        return <WaitForGuideUnscheduled />
    }
    if (sessionLifeCycleState === "wait-for-guide:scheduled") {
        return <WaitForGuideScheduled session={session} />
    }
    if (sessionLifeCycleState === "click-to-start:unscheduled") {
        return <ClickToStartUnscheduled onClick={handleSessionStartClick} />
    }
    if (sessionLifeCycleState === "click-to-start:scheduled") {
        return <WaitForGuideScheduled session={session} />
    }

    // TODO: move userOrAnonymousData to getNextLifecycleState so we can keep the derived state logic in one place
    if (isReadyToRenderSessionPage) {
        return (
            <SessionPage
                session={session}
                anonymousToken={anonymousToken}
                isStartingPrelude={startingPrelude}
                inputsCompleted={inputsCompleted}
                updateInputsCompleted={updateInputsCompleted}
            />
        )
    }

    // TODO: remove once the TS gods are pleased with or typings
    return null
}
