import React from "react"

const Ended = (): React.ReactElement => {
    return (
        <div className="sessionContent isCurrent">
            <h1>Session complete</h1>
        </div>
    )
}

export default Ended
