import React, { FC, ReactNode } from "react"
import { Session } from "../../types/freudTypes"
// TODO: use native Intl features instead of dateformat
// Not using this one since it adds . to the date and native alternatives exist.
// import dateFormat from "dateformat"

const dateFormat = new Intl.DateTimeFormat("en-GB")
const formatDate = (dateString?: number) => {
    if (!dateString) return ""
    return dateFormat.format(new Date(dateString))
}
const timeFormat = new Intl.DateTimeFormat("en-GB", {
    hour: "numeric",
    minute: "numeric",
    hourCycle: "h12",
})
const formatTime = (dateString?: number) => {
    if (!dateString) return ""
    return timeFormat.format(new Date(dateString))
}

interface DialogProps {
    title: string
    description: ReactNode
    cta?: {
        label: string
        onClick: () => void
    }
}

const SessionLoaderDialog: FC<DialogProps> = ({ title, description, cta }) => {
    return (
        <div className="session">
            <div className="sessionContent sessionContent--before-session isCurrent">
                <h1>{title}</h1>
                {description}
                {cta ? (
                    <button className="button primaryAction" onClick={cta.onClick}>
                        {cta.label}
                    </button>
                ) : null}
            </div>
        </div>
    )
}

export const WaitForGuideUnscheduled: FC = () => (
    <SessionLoaderDialog
        title="Welcome"
        description={<p>This session has not yet started. Please wait for your guide to start the session.</p>}
    />
)

export const WaitForGuideScheduled: FC<{ session: Session }> = ({ session }) => (
    <SessionLoaderDialog
        title="Welcome"
        description={
            <>
                <p>
                    <strong>
                        This session has not yet started. It is due to begin on {formatDate(session.startTime)} at{" "}
                        {formatTime(session.startTime)}.
                    </strong>
                    <br />
                    Please wait for your guide to start.
                </p>
            </>
        }
    />
)

export const ClickToStartUnscheduled: FC<{ onClick: () => void }> = ({ onClick }) => (
    <SessionLoaderDialog
        title="Welcome"
        description={<p>This session has not yet started. Tap the button below to start.</p>}
        cta={{
            label: "Start Now",
            onClick,
        }}
    />
)
