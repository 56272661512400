import React from "react"
import Session from "../components/session"
import { JungProvider } from "../../plugins/gatsby-plugin-jung-wrapper/JungContext"

const SessionPage = (props) => (
    <JungProvider>
        <Session {...props} />
    </JungProvider>
)

export default SessionPage
