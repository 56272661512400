import { useEffect, useState } from "react"
import { SessionS } from "."
import * as freudApi from "../../util/freudApiUtil"
import { InputsCompleted } from "./types"
import useSWR from "swr"
import { Session } from "../../types/freudTypes"

export type UseSessionOptions = {
    sessionBroadcastId?: string | string[] | null
    anonymousToken?: string
    isPollingEnabled?: boolean
}

export type UseSessionResult = {
    updateSession: (newSession: SessionS) => void
    startSessionEarly: (sessionID: string, anonymousToken?: string) => Promise<void>
    session: SessionS
    inputsCompleted: InputsCompleted
    updateInputsCompleted: (fn: (state: InputsCompleted) => InputsCompleted) => void
}

export const useSession = ({
    sessionBroadcastId,
    anonymousToken,
    isPollingEnabled = true,
}: UseSessionOptions): UseSessionResult => {
    const [session, setSession] = useState<SessionS>("Loading")
    const [inputsCompleted, setInputsCompleted] = useState<InputsCompleted>({
        checkin: false,
        checkout: false,
        feedback: false,
    })

    // TODO: add error handling (after N failed connection attempts)
    const { data: fetchedSession } = useSWR<Session>(
        anonymousToken && typeof sessionBroadcastId === "string" ? sessionBroadcastId : null,

        async () => {
            if (typeof sessionBroadcastId !== "string") return
            return freudApi.getSignedUpSession(sessionBroadcastId, {
                anonymousToken,
            })
        },
        isPollingEnabled
            ? {
                  refreshInterval: 5000,
              }
            : {
                  // TODO: check if necessary and remove, post impromptu release on 16/12/21
                  dedupingInterval: 5 * 60 * 1000,
              }
    )

    useEffect(() => {
        if (!fetchedSession) return

        freudApi.getSignupMetadata(fetchedSession.id, { anonymousToken }).then((signupMetadata) => {
            setInputsCompleted({
                checkin: !!signupMetadata.preemotionalstate,
                checkout: !!signupMetadata.postemotionalstate,
                feedback: !!signupMetadata.ratings,
            })
        })

        setSession(fetchedSession)
    }, [anonymousToken, fetchedSession])

    const updateSession = (newSession: SessionS) => {
        setSession(newSession)
    }
    const updateInputsCompleted = setInputsCompleted

    const startSessionEarly = async (sessionID: string, anonymousToken?: string): Promise<void> => {
        if (typeof sessionBroadcastId !== "string") return Promise.reject(new Error("Missing sessionBroadcastId."))

        await freudApi.startSessionEarly(sessionID, anonymousToken)
        const newSession = await freudApi.getSignedUpSession(sessionBroadcastId, { anonymousToken })
        return updateSession(newSession)
    }

    return {
        updateSession,
        session,
        inputsCompleted,
        updateInputsCompleted,
        startSessionEarly,
    }
}
