import React from "react"
import formatDuration from "format-duration"

import AudioControl from "./AudioControl"

import "./SessionTimeline.scss"

const SessionTimeline = ({ sessionTimeElapsed, sessionTotalLength }) => {
    return (
        <div className="sessionTimeline">
            <div className="meta">
                <div className="timer">
                    <div className="timeElapsed">
                        {sessionTimeElapsed <= sessionTotalLength
                            ? formatDuration(sessionTimeElapsed)
                            : formatDuration(sessionTotalLength)}
                    </div>
                    /<div className="timeTotal"> {formatDuration(sessionTotalLength)}</div>
                </div>
                <AudioControl isUpsideDown volumeOnly />
            </div>
        </div>
    )
}

export default SessionTimeline
