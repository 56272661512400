import React from "react"
import classNames from "classnames"
import { getDurationComponents } from "../../util/dateUtils"
import { Session } from "../../types/freudTypes"
import FadeLink from "../../components/FadeLink"

const saveSessionData = ({ sessionId, sessionBroadcastId }) => {
    typeof window !== undefined && window.localStorage.setItem("sessionId", sessionId)
    typeof window !== undefined && window.localStorage.setItem("sessionBroadcastId", sessionBroadcastId)
}

const Countdown = ({
    isCurrent,
    timeUntilStart,
    session,
    sessionBroadcastId,
}: {
    isCurrent: boolean
    timeUntilStart: number
    session: Session
    sessionBroadcastId: string | string[] | null | undefined
}): React.ReactElement => {
    const timeWaiting = timeUntilStart
    const {
        day: timeWaitingDays,
        hour: timeWaitingHours,
        minute: timeWaitingMinutes,
        seconds: timeWaitingSeconds,
    } = getDurationComponents(timeWaiting)
    return (
        <div
            className={classNames("sessionContent sessionContent--waiting", {
                isCurrent,
            })}
        >
            <p>Begins in</p>
            <div
                className={classNames("countdown", {
                    withDays: timeWaitingDays > 0,
                    withHours: timeWaitingHours > 0,
                    withMinutes: timeWaitingMinutes > 0,
                })}
            >
                {timeWaitingDays > 0 && (
                    <div className="countdownNumber countdownNumber--days">
                        <div className="time">{timeWaitingDays}</div>
                        <div className="label">Day{timeWaitingDays !== 1 && "s"}</div>
                    </div>
                )}
                {timeWaitingHours > 0 && (
                    <div className="countdownNumber countdownNumber--hours">
                        <div className="time">{timeWaitingHours}</div>
                        <div className="label">Hour{timeWaitingHours !== 1 && "s"}</div>
                    </div>
                )}
                {timeWaitingMinutes > 0 && (
                    <div className="countdownNumber countdownNumber--minutes">
                        <div className="time">{timeWaitingMinutes}</div>
                        <div className="label">Minute{timeWaitingMinutes !== 1 && "s"}</div>
                    </div>
                )}
                <div className="countdownNumber countdownNumber--seconds">
                    <div className="time">{timeWaitingSeconds}</div>
                    <div className="label">Second{timeWaitingSeconds !== 1 && "s"}</div>
                </div>
            </div>
            {session.description && <p>{session.description}</p>}
            {session.type === "groupGuided" && (
                <div className="actionButtons">
                    <FadeLink
                        from="session"
                        className="button button--small"
                        to={"/preparation"}
                        onClick={() => saveSessionData({ sessionId: session.id, sessionBroadcastId })}
                    >
                        Prepare
                    </FadeLink>
                </div>
            )}
        </div>
    )
}
export default Countdown
