import InitialiseAudio from "../InitialiseAudio"
import SessionTimeline from "../SessionTimeline"
import UnsupportedBrowser from "../UnsupportedBrowser"
import UserVisualisation from "../UserVisualisation"
import queryString from "query-string"
import React, { useCallback, useContext, useEffect } from "react"
import Helmet from "react-helmet"
import { SessionS } from "."
import { JungCtx, JungState, SessionState } from "../../../plugins/gatsby-plugin-jung-wrapper/JungContext"
import { useJungAudioSupport } from "../../../plugins/gatsby-plugin-jung-wrapper/useJungAudioSupport"
import SEO from "../SEO"
import SessionLayout from "../SessionLayout"
import { Session } from "../../types/freudTypes"
import Countdown from "./Countdown"
import { LoadingScreen } from "./loadingScreen"
import Postlude from "./Postlude"
import Prelude from "./Prelude"
import { InputsCompleted } from "./types"
import Ended from "./Ended"
import "./session.scss"

export const SessionPage = ({
    session,
    anonymousToken,
    isStartingPrelude,
    inputsCompleted,
    updateInputsCompleted,
}: {
    session: SessionS
    anonymousToken: string
    isStartingPrelude: boolean
    inputsCompleted: InputsCompleted
    updateInputsCompleted: (fn: (state: InputsCompleted) => InputsCompleted) => void
}): React.ReactElement => {
    const jungContext = useContext(JungState)

    useEffect(
        function activateSession() {
            if (session !== "Loading") {
                jungContext.setSession(session.broadcastIdentifier, {
                    anonymousToken,
                    isInfinite: session.type === "groupInfinite",
                })
            }
        },
        [session, anonymousToken, jungContext]
    )

    const jungContextInitializing = jungContext.sessionDuration === 0

    if (!anonymousToken || session === "Loading" || isStartingPrelude || jungContextInitializing) {
        return <LoadingScreen />
    }

    return (
        <SessionPageMain
            location={location}
            session={session}
            jungContext={jungContext}
            anonymousToken={anonymousToken}
            inputsCompleted={inputsCompleted}
            setInputsCompleted={updateInputsCompleted}
        />
    )
}

const isInActiveState = (state: SessionState) => {
    const ACTIVE_STATES = [SessionState.PRELUDE, SessionState.MAIN_PHASE, SessionState.POSTLUDE]
    return ACTIVE_STATES.includes(state)
}

const SessionPageMain = ({
    location,
    session,
    jungContext,
    anonymousToken,
    inputsCompleted,
    setInputsCompleted,
}: {
    location: Location
    session: Session
    jungContext: JungCtx
    anonymousToken: string | undefined
    inputsCompleted: InputsCompleted
    setInputsCompleted: (fn: (state: InputsCompleted) => InputsCompleted) => void
}) => {
    const queryParams = queryString.parse(location.search)
    const sessionBroadcastId = queryParams.session
    const audioSupport = useJungAudioSupport()

    const isInActiveSessionState = isInActiveState(jungContext.sessionState)
    const shouldHaveAudioStream = isInActiveSessionState

    const onInputCompleted = useCallback(
        (step: keyof InputsCompleted) =>
            setInputsCompleted((c: InputsCompleted): InputsCompleted => ({ ...c, [step]: true })),
        [setInputsCompleted]
    )

    const timeElapsed = jungContext.absoluteTime
    const timeTotal = jungContext.sessionDuration

    const activeUsers = jungContext.connectedUserCount

    const onAdvanceFromPrelude = useCallback(() => {
        jungContext.audioOn({ anonymousToken })
        jungContext.advanceFromPrelude()
    }, [anonymousToken, jungContext])

    return (
        <SessionLayout jungState={"blurDimmed"} isSessionActive={isInActiveSessionState}>
            <SEO title="Deep listening session" description="Wavepaths deep listening session." />
            <Helmet
                bodyAttributes={{
                    class: "session",
                }}
                key="helmet"
            />

            {jungContext.sessionState === SessionState.PLANNED && (
                <Countdown
                    isCurrent={jungContext.sessionState === SessionState.PLANNED}
                    session={session}
                    sessionBroadcastId={sessionBroadcastId}
                    timeUntilStart={jungContext.timeUntilStart}
                />
            )}

            {jungContext.sessionState === SessionState.PRELUDE && (
                <Prelude
                    isCurrent={jungContext.sessionState === SessionState.PRELUDE}
                    onAdvanceFromPrelude={onAdvanceFromPrelude}
                    session={session}
                    timeUntilStart={jungContext.timeUntilStart}
                    hasCheckedIn={inputsCompleted.checkin}
                    onInputCompleted={onInputCompleted}
                />
            )}

            {jungContext.sessionState === SessionState.POSTLUDE && (
                <Postlude
                    session={session}
                    jungContext={jungContext}
                    inputsCompleted={inputsCompleted}
                    onInputCompleted={onInputCompleted}
                />
            )}

            {jungContext.sessionState === SessionState.ENDED && <Ended />}

            {isInActiveSessionState && (
                <>
                    {session?.type === "groupGuided" && <UserVisualisation activeUsers={activeUsers} />}
                    <SessionTimeline sessionTimeElapsed={timeElapsed} sessionTotalLength={timeTotal} />
                </>
            )}

            {jungContext.session &&
                jungContext.isAudioOff() &&
                shouldHaveAudioStream &&
                audioSupport === "supported" && <InitialiseAudio text={"Enable audio"} />}
            {audioSupport === "unsupported" && <UnsupportedBrowser location={location} />}
        </SessionLayout>
    )
}
