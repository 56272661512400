import React from "react"
import classNames from "classnames"
import { Session } from "../../types/freudTypes"
import formatDuration from "format-duration"
import PrePostSessionSliders from "../../components/PrePostSessionSliders"

const sessionCountdownString = (timeWaiting: number): string => {
    if (timeWaiting <= 0) {
        return "Your guide will start the session shortly."
    }

    return `This session will begin in ${formatDuration(timeWaiting)}`
}

const PreludeGreeting = ({
    session,
    onAdvanceFromPrelude,
    timeUntilStart,
}: {
    session: Session
    onAdvanceFromPrelude: () => void
    timeUntilStart: number
}): React.ReactElement => (
    <>
        <h1>Welcome</h1>
        {session.canClientStartEarly ? (
            <>
                <p>
                    <strong>Take a moment to get set up and ready, then press below to begin the session.</strong>
                </p>

                <div>
                    <button onClick={onAdvanceFromPrelude} className="button button--startSession">
                        Start Session
                    </button>
                </div>
            </>
        ) : (
            <>
                <p>
                    <strong>{sessionCountdownString(timeUntilStart)}</strong>
                </p>
                <p>Please ensure your audio is on.</p>
            </>
        )}
    </>
)

const CheckinForm = ({
    session,
    onInputCompleted,
}: {
    session: Session
    onInputCompleted: (type: "checkin") => void
}) => (
    <div className={"sessionContent sessionContent--checkin isCurrent"}>
        <h1>Welcome</h1>
        <p>
            <strong>How do you feel in this moment:</strong>
        </p>
        <PrePostSessionSliders sessionId={session.id} type="pre" onSubmit={() => onInputCompleted("checkin")} />
    </div>
)

const Prelude = ({
    session,
    timeUntilStart,
    onAdvanceFromPrelude,
    isCurrent,
    hasCheckedIn,
    onInputCompleted,
}: {
    session: Session
    timeUntilStart: number
    onAdvanceFromPrelude: () => void
    isCurrent: boolean
    hasCheckedIn: boolean
    onInputCompleted: (k: "checkin") => void
}): React.ReactElement => {
    const needsToCheckIn = !hasCheckedIn && session.useEmotionalStateForms
    return (
        <div
            className={classNames("sessionContent sessionContent--prelude", {
                isCurrent,
            })}
        >
            {needsToCheckIn ? (
                <CheckinForm session={session} onInputCompleted={onInputCompleted} />
            ) : (
                <PreludeGreeting
                    timeUntilStart={timeUntilStart}
                    session={session}
                    onAdvanceFromPrelude={onAdvanceFromPrelude}
                />
            )}
        </div>
    )
}

export default Prelude
