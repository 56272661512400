import React, { useState } from "react"
import Ratings from "react-ratings-declarative"
import * as freudApi from "../util/freudApiUtil"
import { useAnonymousToken } from "../hooks"
import ArrowText from "./ArrowText"
import "./PrePostSessionRatings.scss"

const StarRating = ({ rating, onChangeRating }) => (
    <Ratings
        rating={rating}
        widgetHoverColors="yellow"
        widgetRatedColors="yellow"
        widgetDimensions="25px"
        widgetSpacings="0px"
        changeRating={onChangeRating}
    >
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
    </Ratings>
)

const PostSessionRatings = ({ sessionId, onSubmit }) => {
    const { anonymousToken } = useAnonymousToken()

    const [ratings, setRatings] = useState([
        { rating: "journeyRating", value: 0 },
        { rating: "harmonyRating", value: 0 },
        { rating: "meaningRating", value: 0 },
        { rating: "technicalRating", value: 0 },
    ])

    const [feedback, setFeedback] = useState("")

    const onChangeRating = (id, value) => {
        let newState = [...ratings]
        console.log(newState.find((obj) => obj.rating === "journeyRating").value)
        newState.find((e) => e.rating === id).value = parseInt(value)
        console.log(newState)
        setRatings(newState)
    }

    const handleOnChangeFeedback = (value) => {
        setFeedback(value)
        console.log("feedback set " + value)
    }

    const submit = () => {
        event.preventDefault()
        let type = "Ratings"
        freudApi.updateSignupMetadata(sessionId, type, ratings, { anonymousToken })
        freudApi.addQaulitativeFeedback({ anonymousToken }, sessionId, feedback)
        onSubmit && onSubmit()
    }

    const getRating = (key) => {
        return ratings.find((rating) => rating.rating === key).value
    }

    return (
        <div className="endOfSessionFeedbackInput">
            <div className="endOfSessionFeedbackInput--rating">
                <p className="endOfSessionFeedbackInput--ratingLabel">
                    The music provided a sense of being on a journey
                </p>
                <StarRating
                    rating={getRating("journeyRating")}
                    onChangeRating={(r) => onChangeRating("journeyRating", r)}
                />
            </div>
            <div className="endOfSessionFeedbackInput--rating">
                <p className="endOfSessionFeedbackInput--ratingLabel">
                    The music was harmonious and in tune with my inner personal experience
                </p>
                <StarRating
                    rating={getRating("harmonyRating")}
                    onChangeRating={(r) => onChangeRating("harmonyRating", r)}
                />
            </div>
            <div className="endOfSessionFeedbackInput--rating">
                <p className="endOfSessionFeedbackInput--ratingLabel">
                    The experience was personally meaningful for me
                </p>
                <StarRating
                    rating={getRating("meaningRating")}
                    onChangeRating={(r) => onChangeRating("meaningRating", r)}
                />
            </div>
            <div className="endOfSessionFeedbackInput--rating">
                <p className="endOfSessionFeedbackInput--ratingLabel">The music played without problems for me</p>
                <StarRating
                    rating={getRating("technicalRating")}
                    onChangeRating={(r) => onChangeRating("technicalRating", r)}
                />
            </div>
            <div>
                <input value={feedback} onChange={(event) => handleOnChangeFeedback(event.target.value)}></input>
            </div>
            <button className="button button--next" onClick={submit}>
                <ArrowText text={"Next"} />
            </button>
        </div>
    )
}

export default PostSessionRatings
