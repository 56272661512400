import React, { useEffect, useRef } from "react"
import { useWindowSize } from "@react-hook/window-size"

import "./UserVisualisation.scss"

const UserVisualisation = ({ activeUsers }) => {
    let [width, height] = useWindowSize()
    let canvasRef = useRef()

    useEffect(() => {
        let canvas = canvasRef.current
        let ctx = canvas.getContext("2d")
        let centerX = width / 2
        let centerY = height / 2
        let diameter = height * 0.65
        let radius = diameter / 2
        let userDiameter = 2
        let userRadius = userDiameter / 2

        ctx.clearRect(0, 0, width, height)

        for (let i = 0; i < activeUsers; i++) {
            let counterRotation = 33
            let angle = (i / (activeUsers / 2)) * Math.PI - counterRotation
            let x = radius * Math.cos(angle) + centerX
            let y = radius * Math.sin(angle) + centerY

            ctx.beginPath()
            ctx.arc(x - userRadius, y - userRadius, userDiameter, radius, 2 * Math.PI, false)
            ctx.fillStyle = "white"
            ctx.shadowBlur = userDiameter * 2
            ctx.shadowColor = "white"
            ctx.fill()
        }
    }, [activeUsers, width, height])

    return (
        <div className="userVisualisation">
            <canvas ref={canvasRef} width={width} height={height} />
        </div>
    )
}

export default UserVisualisation
