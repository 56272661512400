import dateFormat from "dateformat"

// TODO: use native Intl features instead of dateformat
export const timeUntilDateInMs = (date: number, now: number = new Date().getTime()): number => {
    return date - now
}

export const msToMinutes = (ms) => ms / (60 * 1000)

export const formatDate = (date) => {
    return dateFormat(date, "d.m.")
}

export const formatTime = (date) => {
    return dateFormat(date, "HH:MM")
}

// note: date-fns has an isPast function as well, but this one allows injection of current epoch time which makes testing simpler - WP, 03.11.2020
export const isPast = (date: number, now: number = new Date().getTime()): boolean => timeUntilDateInMs(date, now) < 0

export const getDurationComponents = (milliseconds) => {
    let hour, minute, seconds
    seconds = Math.floor(milliseconds / 1000)
    minute = Math.floor(seconds / 60)
    seconds = seconds % 60
    hour = Math.floor(minute / 60)
    minute = minute % 60
    const day = Math.floor(hour / 24)

    hour = hour % 24
    return {
        day: day,
        hour: hour,
        minute: minute,
        seconds: seconds,
    }
}
