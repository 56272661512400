import React, { useState } from "react"
import * as freudApi from "../util/freudApiUtil"
import "./PrePostSessionSliders.scss"
import { capitalize } from "lodash"
import { useAnonymousToken } from "../hooks"
import Ratings from "react-ratings-declarative"

import ArrowText from "./ArrowText"

type Emotion = "peaceful" | "sad" | "alert" | "tense" | "joyful" | "tired"
type EmotionalState = null | 0 | 1 | 2 | 3 | 4 | 5

const Circles = ({ rating, onChangeRating }) => (
    <Ratings
        rating={rating}
        typeOfWidget="Point"
        widgetHoverColors="yellow"
        widgetRatedColors="yellow"
        changeRating={onChangeRating}
        widgetDimensions="20px"
        svgIconViewBoxes="0 0 100 100"
        svgIconPaths="
        M 25, 50
        a 25,25 0 1,1 50,0
        a 25,25 0 1,1 -50,0
        "
    >
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
    </Ratings>
)

const PrePostSessionSliders = ({
    sessionId,
    type,
    onSubmit,
}: {
    sessionId: string
    type: "pre" | "post"
    onSubmit: () => void
}): React.ReactElement => {
    const { anonymousToken } = useAnonymousToken()

    const [emotionalStates, setEmotionalStates] = useState<Map<Emotion, EmotionalState>>(
        new Map([
            ["peaceful", 0],
            ["sad", 0],
            ["alert", 0],
            ["tense", 0],
            ["joyful", 0],
            ["tired", 0],
        ])
    )

    const handleOnChange = (key: Emotion, value: EmotionalState): void => {
        setEmotionalStates((oldState) => new Map(oldState.set(key, value)))
    }

    const submit = () => {
        const transformedEmotionalStates = Array.from(emotionalStates, ([k, v]: [Emotion, EmotionalState]) => ({
            value: v,
            emotion: k,
        }))
        console.log("submit")
        freudApi.updateSignupMetadata(
            sessionId,
            type === "pre" ? "Pre Emotional State" : "Post Emotional State",
            transformedEmotionalStates,
            { anonymousToken }
        )
        onSubmit()
    }
    return (
        <div className="prePostSessionSliders">
            <div className="sliderContainer">
                {Array.from(emotionalStates, ([k, v]: [Emotion, EmotionalState]) => {
                    return (
                        <div className="inputGroup inputGroup--range" key={k}>
                            <label>{capitalize(k)}</label>
                            <Circles onChangeRating={(val) => handleOnChange(k, val)} rating={v} />
                        </div>
                    )
                })}

                <button className="button button--next" onClick={submit}>
                    <ArrowText text={"Next"} loading={false} />
                </button>
            </div>
        </div>
    )
}

export default PrePostSessionSliders
