import SEO from "../SEO"
import SessionLayout from "../SessionLayout"
import LoadingIndicator from "../LoadingIndicator"
import Helmet from "react-helmet"
import React, { FC } from "react"

export const LoadingScreen: FC = () => (
    <SessionLayout jungState={"blurDimmed"} isSessionActive={false}>
        <SEO title="Deep listening session" description="Wavepaths deep listening session." />
        <Helmet
            bodyAttributes={{
                class: "session",
            }}
            key="helmet"
        />
        <div className="centered">
            <LoadingIndicator loading={true} />
        </div>
    </SessionLayout>
)
