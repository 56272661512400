import React, { useState, useEffect, useContext, useMemo } from "react"
import PropTypes from "prop-types"
import { useTransitionState } from "gatsby-plugin-transition-link/hooks"
import { useSpring, animated, config } from "react-spring"
import classNames from "classnames"
import { debounce } from "lodash"

import "./reset.scss"
import "./SessionLayout.scss"

import { JungContext, useAudioInit } from "../../plugins/gatsby-plugin-jung-wrapper/JungContext"

/* Light Mode 1 */
const colorLM1Elements = "255, 255, 255"
const colorLM1Text = "255, 255, 255"

const SessionLayout = ({ children, isSessionActive, jungState = "blurDimmed" }) => {
    let jungContext = useContext(JungContext)
    let audioInit = useAudioInit()
    let [mounted, setMounted] = useState(false)
    let [isUserIdle, setIsUserIdle] = useState(false)
    let delayedSetUserIdle = useMemo(() => debounce(setIsUserIdle, 5000), [])

    let { mount } = useTransitionState()
    let style = useSpring({
        opacity: mounted ? 1 : 0,
        config: { ...config.slow, duration: 850 },
    })
    useEffect(() => {
        setMounted(mount)
    }, [mount])
    useEffect(() => jungContext.setState(jungState), [jungState])

    useEffect(() => {
        return () => {
            delayedSetUserIdle.cancel()
        }
    }, [delayedSetUserIdle])
    let updateUserIdleState = () => {
        setIsUserIdle(false)
        delayedSetUserIdle(true)
    }

    return (
        <div
            className={classNames("app", { isIdle: isUserIdle && isSessionActive })}
            onClick={audioInit}
            onMouseMove={updateUserIdleState}
            style={{
                "--colorElements": colorLM1Elements,
                "--colorText": colorLM1Text,
            }}
        >
            <main className="appMain">
                <animated.div className="content" style={style}>
                    {children}
                </animated.div>
            </main>
        </div>
    )
}

SessionLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default SessionLayout
