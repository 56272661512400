import React from "react"
import classNames from "classnames"
import { Session } from "../../types/freudTypes"
import { JungCtx, SessionState } from "../../../plugins/gatsby-plugin-jung-wrapper/JungContext"
import PrePostSessionSliders from "../../components/PrePostSessionSliders"
import PostSessionRatings from "../../components/PostSessionRatings"
import { InputsCompleted } from "./types"

const Checkout = ({
    onInputCompleted,
    sessionId,
}: {
    sessionId: string
    onInputCompleted: (input: keyof InputsCompleted) => void
}) => (
    <div className={"sessionContent sessionContent--checkout isCurrent"}>
        <h1>Session complete</h1>
        <p>
            <strong>How do you feel in this moment:</strong>
        </p>
        <PrePostSessionSliders sessionId={sessionId} type="post" onSubmit={() => onInputCompleted("checkout")} />
    </div>
)

const FeedbackForm = ({
    onInputCompleted,
    sessionId,
}: {
    sessionId: string
    onInputCompleted: (input: keyof InputsCompleted) => void
}) => (
    <div className={"sessionContent sessionContent--feedback isCurrent"}>
        <h1>Thank you</h1>
        <p>
            <strong>How was your session?</strong>
        </p>

        <PostSessionRatings sessionId={sessionId} onSubmit={() => onInputCompleted("feedback")} />
    </div>
)

const Postlude = ({
    session,
    jungContext,
    inputsCompleted,
    onInputCompleted,
}: {
    session: Session
    jungContext: JungCtx
    inputsCompleted: InputsCompleted
    onInputCompleted: (input: keyof InputsCompleted) => void
}): React.ReactElement => {
    const needsToCheckout = !inputsCompleted.checkout && session.useEmotionalStateForms
    const needsToProvideFeedback = !needsToCheckout && !inputsCompleted.feedback && session.type === "groupGuided"
    console.log("postlude", jungContext.sessionState === SessionState.POSTLUDE, {
        needsToCheckout,
        needsToProvideFeedback,
        inputsCompleted,
    })
    return (
        <div
            className={classNames("sessionContent sessionContent--checkout isCurrent", {
                isCurrent: jungContext.sessionState === SessionState.POSTLUDE,
            })}
        >
            {needsToCheckout && <Checkout onInputCompleted={onInputCompleted} sessionId={session.id} />}
            {needsToProvideFeedback && <FeedbackForm onInputCompleted={onInputCompleted} sessionId={session.id} />}
            {!needsToCheckout && !needsToProvideFeedback && <h1>Session complete</h1>}
        </div>
    )
}

export default Postlude
