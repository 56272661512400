import { isNil } from "lodash"
import { SessionS } from "."

export type StateId =
    | "initialising"
    | "wait-for-guide:unscheduled"
    // TODO: can we tie this to the session properties more, i.e. cannot-start:unscheduled
    | "wait-for-guide:scheduled"
    | "click-to-start:unscheduled"
    | "click-to-start:scheduled"
    | "default"

export const getNextLifecycleState = ({ session }: { session: SessionS }): StateId => {
    if (session === "Loading") return "initialising"
    // TODO: return if session has ended
    if (!isNil(session.startTime) && new Date(session.startTime) < new Date()) {
        return "default"
    }

    if (!session.canClientStartEarly && isNil(session.startTime)) {
        return "wait-for-guide:unscheduled"
    }
    if (!session.canClientStartEarly && !!session.startTime) {
        return "wait-for-guide:scheduled"
    }
    if (session.canClientStartEarly && !session.startTime) return "click-to-start:unscheduled"

    if (session.canClientStartEarly && session.startTime) return "click-to-start:scheduled"

    // TODO have two separate states: default and init-session
    return "default"
}
